import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: null,
    },
    getters: {
        getUserInfo: state => state.userInfo,
    },
    mutations: {
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo;
        },
        CLEAR_USER_INFO(state) {
            state.userInfo = null;
        }
    },
    actions: {
        setUser(context, userInfoResp) {
            // 将 Java 对象转换为 JavaScript 对象
            const userInfo = {
                userId: userInfoResp.userId,
                name: userInfoResp.name,
                avatar: userInfoResp.avatar,
                accountValidity: userInfoResp.accountValidity,
                phone: userInfoResp.phone,
                userRole: userInfoResp.userRole,
            };

            context.commit('SET_USER_INFO', userInfo);
        },
        clearUser({commit}) {
            commit('CLEAR_USER_INFO');
        }
    },
    modules: {}
})
